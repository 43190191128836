<template>
    <v-row>
      <v-col col="12" :md="md">
        <v-text-field
          v-model="usuario.username"
          label="Usuario"
          :rules="[rules.required]"
        />
      </v-col>
      <v-col col="12" :md="md">
        <v-text-field
          v-model="usuario.password"
          label="Contraseña"
          type="password"
          :rules="[rules.passMinLength]"
        />
      </v-col>
      <v-col col="12" :md="md">
        <v-text-field
          v-model="usuario.password_confirmation"
          label="Confirmación contraseña"
          type="password"
          :rules="[passwordConfirmationRule]"
        />
      </v-col>
      <v-col col="12">
        <span 
          v-if="showSpan"
          class="caption grey--text text--darken-1">
          Este usuario servirá para inciar sesión
        </span>
      </v-col>
    </v-row>
</template>

<script>
import { validationMixin } from '@/mixins/formsValidations.js'
export default {
  mixins: [validationMixin],  
  props: {
    usuario: {
      type: Object,
      default: () => {}
    },
    showSpan: {
      type: Boolean,
      default: true
    },
    md: {
      type: String,
      default: "12"
    }
  },
  computed: {
    passwordConfirmationRule() {
      return this.usuario.password === this.usuario.password_confirmation || "Contraseñas no coinciden"
    }
  }
}
</script>

<style>

</style>